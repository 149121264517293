import { useTranslation } from "react-i18next";

/**
 * @typedef {object} Props
 * @property {string} path
 */

/**
 *
 * @param {Props} param0
 * @returns
 */
const Translator = ({ path }) => {
  const { t } = useTranslation();

  return t(path);
};

export default Translator;
