// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translations: {
    words: {
      amazon: "Amazônia.",
      green: "verdes.",
      "get-started": "Comece agora",
      register: "Cadastre-se",
    },
    header: {
      description: "Invista com propósito pela BVM12!",
      "button-label": "Login",
    },
    "final-banner": {
      text: "Dê um passo em direção a um mundo melhor e mais sustentável hoje!",
    },
    footer: {
      pages: "Páginas",
      "...": {
        label: "Páginas",
        blog: "Blog",
        "blog-post": "Blog Post",
        team: "Team",
        "coming-soon": "Coming Soon",
      },
      "utility-pages": {
        label: "Páginas de utilitários",
        "start-here": "Iniciar aqui",
        "style-guide": "Guia de estilos",
        licenses: "Licensas",
      },
      contacts: {
        label: "Entre em contato conosco",
        email: "Envia-nos um email",
        call: "Envia-nos uma mensagem",
      },
      "follow-us": "Siga-nos",
    },
    "our-office": {
      subtitle: "Nosso esdereço",
      title: "Fale cocosco em Manaus ou no metaverso!",
      "city-description":
        "Estamos presente fisícamente em Manaus e virtualemnte em qualquer lugar do mundo ou do metaverso.",
    },
    "page-home": {
      name: "Início",
      "main-banner": {
        subtitle: "INVESTIMENTO COM PROPÓSITO:",
        title: "A Bolsa Verde de Manaus no coração da ",
        description:
          "Captação de recursos na Amazônia, impulsionando negócios locais e promovendo crescimento sustentável com reflorestamento para neutralizar a pegada de carbono.",
        "input-placeholder": "Insira o seu endereço de email",
        "button-label": "Assinar Newsletter",
        "right-side": {
          title: "Compre sua árvore na Amazônia, agora!",
          description:
            "Ao comprar uma árvore, certificada e tokenizada na Amazônia, você está fortalecendo o meio ambiente e protegendo a floresta.",
          buttonLabel: "Comprar árvore",
        },
      },
      "our-features": {
        "section-name": "NOSSOS RECURSOS",
        title: "Os recursos que tornam o BVM12 única.",
        cards: {
          "invest-in-the-amazon": {
            title: "Reflorestamento",
            description:
              "A BVM12 faz reflorestamento com árvores certificadas e tokenizadas, preservando a maior biodiversidade e compensado a pegada de carbono das empresas.",
          },
          "invest-with-purpose": {
            title: "Captação de Recursos",
            description:
              "A BVM12 conecta fundos de investimentos e bancos a empresas e governos da região amazônica que precisam de capital para fomentarem seus negócios e regiões.",
          },
          "thecnology-meets-nature": {
            title: "Crédito de Carbono",
            description:
              "A BVM12 negocia créditos de carbono tokenizados, oferecendo soluções de compensação para emissões de CO2 por empresas e governos.",
          },
          "gree-capital": {
            title: "Ativos Verdes",
            description:
              "A BVM12 deseja oferecer uma variedade de serviços financeiros com ativos verdes a investidores e instituições financeiras comprometidos com o meio ambiente.",
          },
          palafita: {
            title: "Palafita",
            description:
              "Palafita é a criptomoeda social escolhida pela BVM12 para realizar transações de compra e venda dentro da plataforma de negociação de ativos.",
          },
          "cvm-approved": {
            title: "Aprovado pela CVM",
            description:
              "O BVM12 foi aprovado pela CVM, Comissão de Valores Mobiliários do Brasil, para ajudar as startups a captar recursos por meio de IPOs e financiamento privado.",
          },
        },
        "button-getstarted": "Iniciar",
        "button-browse-features": "Navegue por todos os recursos",
      },
      "mid-banner": {
        subtitle: "NOSSOS PRODUTOS",
        title:
          "Somos o próximo passo na captação de recursos e investimentos verdes",
        nft: {
          title: "Captação de Recursos",
          description:
            "A BVM12 lidera a captação responsável de recursos na Amazônia, impulsionando o desenvolvimento sustentável. Ao conectar investidores a empresas locais, promovemos o equilíbrio entre crescimento econômico e preservação ambiental.",
        },
        esg: {
          title: "Reflorestamento",
          description:
            "A BVM12 lidera o reflorestamento certificado e tokenizado por meio de blockchain na Amazônia, restaurando áreas degradadas. Contribuímos para a preservação ambiental, garantindo transparência e sustentabilidade em cada passo.",
        },
        co2: {
          title: "Crédito de Carbono",
          description:
            "A BVM12 inova ao tokenizar créditos de carbono na Amazônia, utilizando blockchain. Essa solução permite a negociação eficiente no mercado balcão, impulsionando a preservação ambiental e oferecendo uma abordagem sustentável para investidores.",
        },
      },
      "get-started": {
        subtitle: "Iniciar",
        title: "Nunca foi tão fácil investir em ativos",
        cards: {
          first: {
            title: "Faça o seu cadastro em nossa plataforma.",
            description:
              "O cadastro é simples e rápido. Logo após concluí-lo, você terá uma conta para realizar transações financeiras.",
          },
          second: {
            title: "Escolha um 'green token' de sua preferência.",
            description:
              "Após efetuar o cadastro em sua conta, você terá acesso ao home broker para comprar e vender seus green tokens.",
          },
          third: {
            title: "Compre o token de forma segura na plataforma.",
            description:
              "Realize transações com segurança ao utilizar Patafita como método de pagamento na aquisição de tokens.",
          },
        },
        "button-label": "Iniciar",
      },
    },
    "page-about-us": {
      name: "Sobre nós",
      "main-banner": {
        subtitle: "Sobre nós",
        title: "Temos a missão de fomentar a economia na Amazônia.",
        description:
          "Através da tecnologia e da criatividade temos trabalhado para o crescimento da economia e preservação da região amazônica, sempre com iniciativas sustentáveis e escalaveis.",
        "join-button-label": "Junte-se ao nosso time",
        "our-company-button-label": "A história da nossa empresa",
      },
      "about-us": {
        subtitle: "Por que?",
        title: "O que nos motiva a estarmos na Amazônia trabalhando?",
        description:
          "Nosso propósito fundamental é contribuir para a sustentabilidade e conservação desse ecossistema vital. Ao focar no 'Por quê', buscamos não apenas resultados tangíveis, mas também um impacto significativo e positivo no meio ambiente e nas comunidades locais. Estamos comprometidos com a missão de preservar a Amazônia para as gerações futuras, e é essa convicção que nos impulsiona diariamente em nosso trabalho na região.",
        "subtitle-section-two": "Como?",
        "title-section-two": "Como queremos fomentar a economia na Amazônia?",
        "description-section-two":
          "Na BVM12, utilizamos blockchain para simplificar a compra e venda de créditos de carbono, impulsionando o reflorestamento na Amazônia. Contratos inteligentes transparentes incentivam empresas a compensarem emissões. Plataformas digitais conectam investidores a projetos locais, captando crédito para empresas e garantindo rastreabilidade das ações de reflorestamento. Essa abordagem inovadora promove não apenas a preservação ambiental, mas também o desenvolvimento econômico sustentável na região, criando um impacto positivo duradouro.",
        "subtitle-section-two": "Como?",
        "title-section-two": "Como queremos fomentar a econmia na Amazônia?",
        "description-section-two":
          "Na BVM12, utilizamos blockchain para simplificar a compra e venda de créditos de carbono, impulsionando o reflorestamento na Amazônia. Contratos inteligentes transparentes incentivam empresas a compensarem emissões. Plataformas digitais conectam investidores a projetos locais, captando crédito para empresas e garantindo rastreabilidade das ações de reflorestamento. Essa abordagem inovadora promove não apenas a preservação ambiental, mas também o desenvolvimento econômico sustentável na região, criando um impacto positivo duradouro.",

        "subtitle-section-three": "O que somos?",
        "title-section-three": "O que é uma Bolsa Verde?",
        "description-section-three":
          "A BVM12 é uma plataforma de negócios da economia verde, utilizando blockchain para simplificar a transação de créditos de carbono e impulsionar o reflorestamento na Amazônia. Contratos inteligentes incentivam empresas a compensar emissões, enquanto plataformas digitais conectam investidores a projetos locais, captando crédito para empresas. Essa abordagem inovadora não só promove a preservação ambiental, mas também estimula o desenvolvimento econômico sustentável na região, gerando um impacto positivo e significativo.",
      },
      "mid-banner": {
        subtitle: "Nosso time",
        title: "Conheça a equipe incrível atrás do BVM 12",
        "join-our-team-button-label": "Juntar-se ao time",
        "see-everybody-button-label": "Ver todos",
      },
    },
    "page-products": {
      name: "Produtos",
      "main-banner": {
        subtitle: "Nossos produtos",
        title:
          "Revolucionando a Sustentabilidade na Amazônia com Blockchain Verde.",
        description:
          "Nossos produtos utilizam tecnologia e criatividade para gerar valor a região amazônica e ao meio ambiente",
        "join-button-label": "Junte-se ao nosso time",
        "our-company-button-label": "A história da nossa empresa",
      },
      "our-numbers": {
        subtitle: "Nossos",
        title: "Temos resultados impas",
        "customer-satisfaction": "Satisfação do cliente",
        "active-clients": "Clientes ativos",
        "team-members": "Membros do time",
        "company-growth": "Crescimento da empresa",
      },
      products: {
        subtitle: "Reflorestamento",
        title: "Temos a missão de ar impacto ambiental e social na Amazônia",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "subtitle-section-two": "Sobre nós",
        "title-section-two":
          "Queremos democratizar a capacidade de acesso a fundos e crescer sua empresa",
        "description-section-two":
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "subtitle-section-three": "About us",
        "title-section-three":
          "We want to democratize the teste to access funds and grow your company",
        "description-section-three":
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      },
      "our-values": {
        subtitle: "Nossos valores",
        title: "Os valores que norteiam tudo o que fazemos",
        inovation: {
          title: "Inovação",
          description:
            "Vehicula ipsum a arcu cursus vitae rius morbi enim nunc faucibus integer mal esuada nunc vel risus.",
        },
        growth: {
          title: "Crescimento",
          description:
            "Vehicula ipsum a arcu cursus vitae rius morbi enim nunc faucibus integer mal esuada nunc vel risus.",
        },
        passion: {
          title: "Paixão",
          description:
            "Vehicula ipsum a arcu cursus vitae rius morbi enim nunc faucibus integer mal esuada nunc vel risus.",
        },
      },
      "mid-banner": {
        subtitle: "Nosso time",
        title: "Conheça a equipe incrível atrás do BVM 12",
        "join-our-team-button-label": "Juntar-se ao time",
        "see-everybody-button-label": "Ver todos",
      },
    },
    "page-contact": {
      name: "Contato",
      form: {
        subtitle: "CONTATE-NOS",
        title: "Entre em contato hoje mesmo",
        name: {
          label: "Nome",
          placeholder: "Digite seu nome",
        },
        email: {
          label: "Email",
          placeholder: "Digite seu e-mail",
        },
        phone: {
          label: "Telefone",
          placeholder: "Digite seu telefone",
        },
        subject: {
          label: "Objetivo",
          placeholder: "Digite seu objetivo",
        },
        message: {
          label: "Em que podemos ajudar?",
          placeholder: "Por favor, escreva sua mensagem aqui...",
        },
        "submit-button-label": "Enviar mensagem",
      },
      "mid-banner": {
        subtitle: "BVM 12",
        title: "Frequently Asked Questions",
      },
    },
  },
};
