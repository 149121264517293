import React from "react";
import "./style.scss";
import Header from "../../components/Header";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../components/Footer";
import banner from "../../assets/images/banner-ecotrade.png";
import Story1 from "../../assets/images/logo-ifood.png";
import Story2 from "../../assets/images/moto-ifood.png";
import Story3 from "../../assets/images/deliveryman.png";
import value1 from "../../assets/images/value1.png";
import value2 from "../../assets/images/value2.png";
import value3 from "../../assets/images/value3.png";
import value4 from "../../assets/images/value4.png";
import value5 from "../../assets/images/value5.png";
import value6 from "../../assets/images/value6.png";
import team from "../../assets/images/client.png";

import Translator from "../../components/Translator";
import OurOfficeBanner from "../../components/OurOfficeBanner";
import FooterBanner from "../../components/FooterBanner";

const pageTranslateId = "page-products";

const EcoTrade = () => {
  return (
    <div className="products-main">
      {/* //header section */}
      <Header />
      {/* about us banner starts */}
      <section className="products-banner">
        <div className="title-wrap">
          <h5>
            EcoTrade
            {/*<Translator path={`${pageTranslateId}.main-banner.subtitle`} />*/}
          </h5>
          <h3>
            Desenvolvida pela BVM12, a EcoTrade simplifica a venda direta de créditos de carbono para entregadores do iFood.
            {/*<Translator path={`${pageTranslateId}.main-banner.title`} />*/}
          </h3>
          <p>
            A plataforma permite aos entregadores listar e vender créditos nos aplicativos iFood, usando contratos inteligentes para segurança e transparência.
            {/*<Translator path={`${pageTranslateId}.main-banner.description`} />*/}
          </p>
          {/*
          <div className="button-group-wrap">
            <button className="join-team-btn">
              <Translator
                path={`${pageTranslateId}.main-banner.join-button-label`}
              />
            </button>
            <button className="company-btn">
              <Translator
                path={`${pageTranslateId}.main-banner.our-company-button-label`}
              />
            </button>
          </div>
          */}
        </div>
        <div className="banner-footer-img">
          <img src={banner} alt="banner" />
        </div>
      </section>

      {/* about us story section */}
      <section className="products-story">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="story-details-wrap">
                {/*
                  <h5>
                    <Translator path={`${pageTranslateId}.products.subtitle`} />
                  </h5>
                */}
                <h3>
                  Integrada à Mastercard, proporciona uma experiência acessível e eficiente
                  {/*<Translator path={`${pageTranslateId}.products.title`} />*/}
                </h3>
                <p>
                  Pagamentos podem ser recebidos em tokens ou moeda tradicional, ampliando  as opções de negociação. Além disso, para incentivar práticas sustentáveis, a EcoTrade oferece descontos especiais para iFood e BVM12 ao adquirirem créditos de carbono diretamente dos entregadores. Além de recompensar esforços ecológicos, a EcoTrade fortalece a colaboração entre BVM12, iFood e Mastercard para uma economia mais verde.
                  {/*<Translator path={`${pageTranslateId}.products.description`} />*/}
                </p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="story-image">
                <img src={Story1} alt="story about us" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="story-image">
                <img src={Story2} alt="story about us" />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="story-details-wrap">
                {/*
                  <h5>
                    <Translator
                      path={`${pageTranslateId}.products.subtitle-section-two`}
                    />
                  </h5>
                */}
                <h3>
                  Certificação dos Créditos de Carbono
                  {/*<Translator path={`${pageTranslateId}.products.title-section-two`} /> */}
                </h3>
                <p>
                  Na fase de certificação de emissões, a BVM12 adota uma abordagem  abrangente. Coletamos dados detalhados de cada moto elétrica, incluindo  modelo, eficiência e fonte de energia. Monitoramos continuamente o  desempenho em tempo real, utilizando sensores e telemetria.
                  {/*<Translator path={`${pageTranslateId}.products.description-section-two`} /> */}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="story-details-wrap">
                {/*
                  <h5>
                    <Translator path={`${pageTranslateId}.products.subtitle-section-three`} />
                  </h5>
                */}
                <h3>
                  Certificação de Emissões das Motos
                  {/*<Translator path={`${pageTranslateId}.products.title-section-three`} /> */}
                </h3>
                <p>
                  Ao estabelecer uma linha de base com as emissões de motos a combustão, a BVM12 certifica as não emissões de CO2, considerando também a origem da energia para carregar as baterias. Auditorias independentes garantem a validade e integridade dos dados, proporcionando uma certificação confiável e transparente.
                  {/*<Translator path={`${pageTranslateId}.products.description-section-three`} /> */}
                </p>
                <p>
                  Junte-se a nós nesse movimento rumo a um futuro sustentável. A EcoTrade, da BVM12: conectando entregadores, plataformas e créditos de carbono para um impacto positivo no meio ambiente.
                </p>
                <p>
                  A EcoTrade é um projeto executado pela BVM12 - Plataforma de Captação para Startups Ltda. CNPJ 38.730.632/0001-83
                </p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="story-image">
                <img src={Story3} alt="Deliveryman" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* our values 
      <section className="our-value-wrap">
        <div className="title-wrap">
          <h5>
            <Translator path={`${pageTranslateId}.our-values.subtitle`} />
          </h5>
          <h3>
            <Translator path={`${pageTranslateId}.our-values.title`} />
          </h3>
        </div>
        <Row className="g-2">
          <Col sm={12} md={6} lg={4} xl={4}>
            <div className="value-card-wrap">
              <div className="card-image">
                <img src={value1} alt="card" />
              </div>
              <h3>
                <Translator
                  path={`${pageTranslateId}.our-values.inovation.title`}
                />
              </h3>
              <p>
                Vehicula ipsum a arcu cursus vitae rius morbi enim nunc faucibus
                integer mal esuada nunc vel risus.
              </p>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <div className="value-card-wrap">
              <div className="card-image">
                <img src={value2} alt="card" />
              </div>
              <h3>
                <Translator
                  path={`${pageTranslateId}.our-values.growth.title`}
                />
              </h3>
              <p>
                Vehicula ipsum a arcu cursus vitae rius morbi enim nunc faucibus
                integer mal esuada nunc vel risus.
              </p>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <div className="value-card-wrap">
              <div className="card-image">
                <img src={value6} alt="card" />
              </div>
              <h3>
                <Translator
                  path={`${pageTranslateId}.our-values.passion.title`}
                />
              </h3>
              <p>
                Vehicula ipsum a arcu cursus vitae rius morbi enim nunc faucibus
                integer mal esuada nunc vel risus.
              </p>
            </div>
          </Col>
        </Row>
      </section>
      */}

      {/* our office section */}
      <OurOfficeBanner />

      {/* footer banner section */}
      <FooterBanner isSimple />
      {/* //footer */}
      <Footer />
    </div>
  );
};

export default EcoTrade;
