import React from 'react'
import "./style.scss";
import Header from "../../components/Header";
import { Container, Row, Col} from "react-bootstrap";
import Footer from "../../components/Footer";
import ClientImg from '../../assets/images/client.png';
import facebook from '../../assets/images/facebook-white.svg';
import twitter from '../../assets/images/twitter-white.svg';
import instagram from '../../assets/images/insta-white.svg';
import linkedin from '../../assets/images/linked-white.svg';
const personal = () => {
  return (
    <div className='personal-main'>
        {/* header section */}
        <Header/>
        <section className='personal-banner'>
            <div className='personal-detail-wrapper'>
                <Row>
                   <Col sm={12} md={6} lg={6} xl={6}>
                     <img className="left-img-wrap" src={ClientImg} alt="person"/>
                   </Col>
                   <Col sm={12} md={6} lg={6} xl={6}>
                       <div className='detail-wrap'>
                          <h5>CFO</h5>
                          <h3>Kyung Lee</h3>
                          <p>Aenean pellentesque sed tincidunt situ dictum quam porta volutpat condimentum scelerisque in eget id mattis eu ultrices adipiscing tellus leo non odio ac malesuada egestas quis posuer magna ac eleifend amet elementum risus lectus tincidunt.</p>
                          <div className="social-icon">
                            <ul>
                              <li><img src={facebook} alt="facbook"/></li>
                              <li><img src={twitter} alt="twitter"/></li>
                              <li><img src={instagram} alt="instagram"/></li>
                              <li><img src={linkedin} alt="Linked in"/></li>
                            </ul>
                          </div>
                       </div>
                   </Col>
                </Row>
            </div>
        </section>

        {/* about section starts */}
        <section className='person-about-us'>
            <Container>
              <Row className='align-items-center justify-content-center'>
                <Col sm={12} md={10} lg={7} xl={7}>
                  <div className='about-main'>
                     <h3>About Kyung Lee</h3>
                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit lobortis arcu enim urna adipiscing praesent velit viverra sit semper lorem eu cursus vel hendrerit elementum morbi curabitur etiam nibh justo, lorem aliquet donec sed sit mi dignissim at ante massa mattis:</p>
                      <ol>
                        <li>Neque sodales ut etiam sit amet nisl purus non tellus orci ac auctor</li>
                        <li>Adipiscing elit ut aliquam purus sit amet viverra suspendisse potenti dolor sit eamel</li>
                        <li>Mauris commodo quis imperdiet massa tincidunt nunc pulvinar</li>
                        <li>Adipiscing elit ut aliquam purus sit amet viverra suspendisse potenti consectur</li>
                      </ol>
                  </div>
                  <div className='about-main'>
                     <h3>Kyng Lee experience</h3>
                     <p>Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere praesent tristique magna sit amet purus gravida quis blandit turpis.</p>
                     <p>At risus viverra adipiscing at in tellus integer feugiat nisl pretium fusce id velit ut tortor sagittis orci a scelerisque purus semper eget at lectus urna duis convallis. porta nibh venenatis cras sed felis eget neque laoreet suspendisse interdum consectetur libero id faucibus nisl donec pretium vulputate sapien nec sagittis aliquam nunc lobortis mattis aliquam faucibus purus in.</p>
                      <ol>
                        <li>Neque sodales ut etiam sit amet nisl purus non tellus orci ac auctor</li>
                        <li>Adipiscing elit ut aliquam purus sit amet viverra suspendisse potenti dolor sit eamel</li>
                        <li>Mauris commodo quis imperdiet massa tincidunt nunc pulvinar</li>
                        <li>Adipiscing elit ut aliquam purus sit amet viverra suspendisse potenti consectur</li>
                      </ol>
                  </div>
                </Col>
              </Row>
            </Container>
        </section>

      {/* footer section */}
      <Footer/>
    </div>
   
  )
}

export default personal