// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translations: {
    words: {
      amazon: "Amazon",
      green: "green",
      "get-started": "Get Started",
    },
    header: {
      description: "Be a BVM 12 client today!",
      "button-label": "Login",
    },
    "final-banner": {
      text: "Take a step into a better a better and more sustainable world today!",
    },
    footer: {
      pages: "Pages",
      "...": {
        label: "Pages",
        blog: "Blog",
        "blog-post": "Blog Post",
        team: "Team",
        "coming-soon": "Coming Soon",
      },
      "utility-pages": {
        label: "Utility Pages",
        "start-here": "Start here",
        "style-guide": "Style Guide",
        licenses: "Licenses",
      },
      contacts: {
        label: "Get it touch with us",
        email: "Email us",
        call: "Send us a message",
      },
    },
    "our-office": {
      subtitle: "Our Office",
      title: "Come and visit one of our offices",
      "city-description":
        "Vehicula ipsum a arcu cursus vitae rius morbi enim nunc faucibus integer mal esuada.",
    },
    "page-home": {
      name: "Home",
      "main-banner": {
        subtitle: "FUNDRAISING AND INVESTMENTS",
        title: "The ONE investment exchange located in the heart of the ",
        description:
          "We raise funds for your company through the intelligent use of technology. Through blockchain, we help companies achieve sustainable growth for their business and the environment!",
        "input-placeholder": "Enter your email address",
        "button-label": "Newsletter Subscribe",
        "right-side": {
          title: "Buy your tree on Amazon, now!",
          description:
            "When you buy a tree, certified and tokenized in the Amazon, you are strengthening the environment and protecting the forest.",
          buttonLabel: "Buy tree",
        },
      },
      "our-features": {
        "section-name": "OUR FEATURES",
        title: "The Features That Make BVM 12 Unique",
        cards: {
          "invest-in-the-amazon": {
            title: "Invest in the Amazon",
            description:
              "BVM12 focuses in protecting and preserving the largest and most biodiverse tract of tropical rainforest in the world by promoting and investing in green startup companies",
          },
          "invest-with-purpose": {
            title: "Invest with Purpose",
            description:
              "BVM12 connects world’s smart and responsible investment community to the green startup industries in the Amazon, providing nice return to the investors.",
          },
          "thecnology-meets-nature": {
            title: "Technology meets Nature",
            description:
              "BVM12 uses blockchain technology to simplify the complexity of global investment into simple, dependable, and transparent transactions, promoting efficiency.",
          },
          "gree-capital": {
            title: "Green Capital",
            description:
              "If you are an investor through BVM12, you can be assured that your capital will always be directed to the environmentally conscientious and socially responsible.",
          },
          palafita: {
            title: "Palafita",
            description:
              "Palafita means stilt houses in Portuguese, which also represent many river basin dwellings built over the Amazon River to protect its residents against flooding.",
          },
          "cvm-approved": {
            title: "CVM Approved",
            description:
              "BVM12 has been approved by CVM, the Securities and Exchange Commission of Brazil, to help the startup companies raise funds through IPOs and private funding.",
          },
        },
        "button-getstarted": "Get Started",
        "button-browse-features": "Browse All Features",
      },
      "mid-banner": {
        subtitle: "OUR PRODUCT",
        title: "We are the next step in green fundraising and investment",
        nft: {
          title: "NFT",
          description:
            "Non-Fungible Token is a special model of cryptographic token that represents something unique. Unlike cryptocurrencies like Bitcoin and other utility tokens, NFTs cannot be exchanged.",
        },
        esg: {
          title: "ESG",
          description:
            "Environmental, Social and Governance is not a new topic. It has been used for many years to refer to the environmental, social and governance aspects that are observed by market investors when analyzing companies and making investment decisions.",
        },
        co2: {
          title: "CO2",
          description:
            "Carbon credit, aimed at reducing greenhouse gases. These credits are a form of flexibility, helping countries that have goals to reduce the emission of polluting gases to achieve them.",
        },
      },
      "get-started": {
        subtitle: "Get Started",
        title: "It’s never been easier to fundraise and go",
        cards: {
          first: {
            title: "Get in touch with our marvelous team",
            description:
              "Leave your email in our website or just contact us through one of our channels in the site or the social media profiles",
          },
          second: {
            title:
              "Sit down with the team and lay down a unique strategy for you company",
            description:
              "We get together and develop a tailored strategy that will fit your company and help you go fundraising and go green.",
          },
          third: {
            title: "Start accessing funds beyond your own imagination",
            description:
              "Together we can accomplish much greater things in a responsible and sustainable way.",
          },
        },
        "button-label": "Get Started",
      },
    },
    "page-about-us": {
      name: "About us",
      "main-banner": {
        subtitle: "About us",
        title: "We are on a mission to empower businesses world wide",
        description:
          "Through technology and creativity we have been making easier to companies, no matter the size, to grow and achieve their dreams.",
        "join-button-label": "Join our team",
        "our-company-button-label": "Our company story",
      },
      "about-us": {
        subtitle: "About us",
        title: "The story behind our fundraising firm",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "subtitle-section-two": "About us",
        "title-section-two":
          "We want to democratize the ability to access funds and grow your company",
        "description-section-two":
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",

        "subtitle-section-three": "About us section three",
        "title-section-three": "Section three",
        "description-section-three": "Section three",
      },
      "mid-banner": {
        subtitle: "Our team",
        title: "Meet the amazing team behind BVM 12",
        "join-our-team-button-label": "Join Team",
        "see-everybody-button-label": "See everybody",
      },
    },
    "page-products": {
      name: "Products",
      "main-banner": {
        subtitle: "About us",
        title: "We are on a mission to empower businesses world wide",
        description:
          "Through technology and creativity we have been making easier to companies, no matter the size, to grow and achieve their dreams.",
        "join-button-label": "Join our team",
        "our-company-button-label": "Our company story",
      },
      "our-numbers": {
        subtitle: "Our numbers",
        title: "We have impactful results",
        "customer-satisfaction": "Customer satisfaction",
        "active-clients": "Active clients",
        "team-members": "Team members",
        "company-growth": "Company growth",
      },
      products: {
        subtitle: "About us",
        title: "The story behind our fundraising firm",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "subtitle-section-two": "About us",
        "title-section-two":
          "We want to democratize the ability to access funds and grow your company",
        "description-section-two":
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",

        "subtitle-section-three": "About us",
        "title-section-three":
          "We want to democratize the ability to access funds and grow your company",
        "description-section-three":
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      },
      "our-values": {
        subtitle: "Our values",
        title: "The values that drive everything we do",
        inovation: {
          title: "Inovation",
          description:
            "Vehicula ipsum a arcu cursus vitae rius morbi enim nunc faucibus integer mal esuada nunc vel risus.",
        },
        growth: {
          title: "Growth",
          description:
            "Vehicula ipsum a arcu cursus vitae rius morbi enim nunc faucibus integer mal esuada nunc vel risus.",
        },
        passion: {
          title: "Passion",
          description:
            "Vehicula ipsum a arcu cursus vitae rius morbi enim nunc faucibus integer mal esuada nunc vel risus.",
        },
      },
      "mid-banner": {
        subtitle: "Our team",
        title: "Meet the amazing team behind BVM 12",
        "join-our-team-button-label": "Join Team",
        "see-everybody-button-label": "See everybody",
      },
    },
    "page-contact": {
      name: "Contact",
      form: {
        subtitle: "CONTACT US",
        title: "Get in touch today",
        name: {
          label: "Name",
          placeholder: "Enter Name",
        },
        email: {
          label: "Email",
          placeholder: "Enter Email",
        },
        phone: {
          label: "Phone",
          placeholder: "Enter Phone",
        },
        subject: {
          label: "Subject",
          placeholder: "Enter Subject",
        },
        message: {
          label: "How can help you ?",
          placeholder: "Please type your message here...",
        },
        "submit-button-label": "Send Message",
      },
      "mid-banner": {
        subtitle: "BVM 12",
        title: "Frequently Asked Questions",
      },
    },
  },
};
