import React, { useEffect, useState } from "react";
import "./style.scss";
import Header from "../../components/Header";
import { Container, Row, Col, Form, Button, Accordion } from "react-bootstrap";

import OurOfficeBanner from "../../components/OurOfficeBanner";
import FooterBanner from "../../components/FooterBanner";
import Footer from "../../components/Footer";
import Translator from "../../components/Translator";

import countries from "../../data/countries.json";
import { useMask } from "@react-input/mask";

const translateId = "page-contact";

const Contact = () => {
  const [currentCountry, setCurrentCountry] = useState(countries[0]);

  const inputNumberRef = useMask({
    mask: currentCountry.phoneMask ?? "_______________",
    replacement: { _: /\d/ },
  });

  const handleChangePhoneCountry = (e) => {
    setCurrentCountry(countries.find((item) => item.codigo === e.target.value));
  };

  return (
    <div className="main-layout">
      {/* header section */}
      <Header />
      {/* contact section */}
      <section className="get-in-touch-wrapper">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col>
              <div className="common-title-wrap">
                <p>
                  <Translator path={`${translateId}.form.subtitle`} />
                </p>
                <h3>
                  <Translator path={`${translateId}.form.title`} />
                </h3>
              </div>

              <div className="contactus-form">
                <Form className="form-main">
                  <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          <Translator path={`${translateId}.form.name.label`} />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={Translator({
                            path: `${translateId}.form.name.placeholder`,
                          })}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          <Translator
                            path={`${translateId}.form.email.label`}
                          />
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder={Translator({
                            path: `${translateId}.form.email.placeholder`,
                          })}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          <Translator
                            path={`${translateId}.form.phone.label`}
                          />
                        </Form.Label>
                        <div className="d-flex gap-2">
                          <Form.Select
                            className="select-country"
                            size="sm"
                            onChange={handleChangePhoneCountry}
                          >
                            {countries.map((item) => (
                              <option key={item.codigo} value={item.codigo}>
                                +{item.fone.replaceAll("0", "")} - {item.nome}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control
                            placeholder={Translator({
                              path: `${translateId}.form.phone.placeholder`,
                            })}
                            ref={inputNumberRef}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          <Translator
                            path={`${translateId}.form.subject.label`}
                          />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={Translator({
                            path: `${translateId}.form.subject.placeholder`,
                          })}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={12} lg={12} xl={12}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          <Translator
                            path={`${translateId}.form.message.label`}
                          />
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder={Translator({
                            path: `${translateId}.form.message.placeholder`,
                          })}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button className="send-msg-btn">
                    <Translator
                      path={`${translateId}.form.submit-button-label`}
                    />
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <OurOfficeBanner withButton={false} />

      {/* footer banner section */}
      <FooterBanner isSimple />

      {/* footer section*/}
      <Footer />
    </div>
  );
};
export default Contact;
