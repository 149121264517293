import React from "react";
import "./style.scss";
import Header from "../../components/Header";
import { Container, Row, Col, Form, Button, Carousel } from "react-bootstrap";
import bannerImg from "./../../assets/images/banner-img.png";
import Feature1 from "./../../assets/images/feature-1.png";
import Feature2 from "./../../assets/images/feature-2.png";
import Feature3 from "./../../assets/images/feature-3.png";
import Feature4 from "./../../assets/images/feature-4.png";
import Feature5 from "./../../assets/images/feature-5.png";
import Feature6 from "./../../assets/images/feature-6.png";
import productImg from "./../../assets/images/product-left-bg.png";
import Product1 from "./../../assets/images/product-1.png";
import Product2 from "./../../assets/images/product-2.png";
import Product3 from "./../../assets/images/product-3.png";
import getStarted1 from "../../assets/images/get-started-1.png";
import getStarted2 from "../../assets/images/get-started-2.png";
import getStarted3 from "../../assets/images/get-started-3.png";
import number1 from "../../assets/images/number-1.png";
import number2 from "../../assets/images/number-2.png";
import number3 from "../../assets/images/number-3.png";
import Footer from "../../components/Footer";

import Translator from "../../components/Translator";
import FooterBanner from "../../components/FooterBanner";
import { Link } from "react-router-dom";

const translatorId = "page-home";

const home = () => {
  return (
    <div className="main-layout">
      {/* header section */}
      <Header />
      {/* top banner section */}
      <Container className="top-banner-wrapper" fluid>
        <Row>
          <Col sm={12} md={6} lg={6} xl={6} className="p-0">
            <div className="left-banner-details">
              <h5>
                <Translator path={`${translatorId}.main-banner.subtitle`} />
              </h5>
              <h2>
                <Translator path={`${translatorId}.main-banner.title`} />
                <span>
                  <Translator path={`words.amazon`} />
                </span>
              </h2>
              <p>
                <Translator path={`${translatorId}.main-banner.description`} />
              </p>
              <div className="email-letter-wrap">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder={Translator({
                        path: `${translatorId}.main-banner.input-placeholder`,
                      })}
                    />
                  </Form.Group>
                </Form>
                <Button className="begin-btn-wrap">
                  <Translator
                    path={`${translatorId}.main-banner.button-label`}
                  />
                </Button>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={6} className="p-0">
            <div className="right-banner-detail">
              <Carousel>
                <Carousel.Item>
                  <div
                    style={{ width: "70%", margin: "0 auto" }}
                    className="d-flex justify-content-center align-items-center flex-column custom-carousel-item"
                  >
                    <div className="d-flex gap-4">
                      <h2>
                        <Translator
                          path={`${translatorId}.main-banner.right-side.title`}
                        />
                      </h2>
                      <img
                        alt="imagem de uma árvore ilustrativa"
                        src="/arvore-site-institucional.svg"
                      />
                    </div>
                    <div className="d-flex gap-4">
                      <p>
                        <Translator
                          path={`${translatorId}.main-banner.right-side.description`}
                        />
                      </p>
                      <button className="buy-tree ml-4">
                        <Translator
                          path={`${translatorId}.main-banner.right-side.buttonLabel`}
                        />
                      </button>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex flex-column gap-5 custom-carousel-item">
                    <img
                      alt="generic banner"
                      src={bannerImg}
                      className="right-img-banner"
                    />
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>

      {/* feature section starts */}
      <section className="feature-section-wrapper">
        <div className="common-title-wrap">
          <p>
            <Translator path={`${translatorId}.our-features.section-name`} />
          </p>
          <h3>
            <Translator path={`${translatorId}.our-features.title`} />
          </h3>
        </div>
        <Container className="custom-width">
          <Row className="g-2 mt-5">
            <Col sm={6} md={6} lg={4} xl={4}>
              <div className="feature-card-common">
                <img src={Feature1} alt="img" />
                <h3>
                  <Translator
                    path={`${translatorId}.our-features.cards.invest-in-the-amazon.title`}
                  />
                </h3>
                <p>
                  <Translator
                    path={`${translatorId}.our-features.cards.invest-in-the-amazon.description`}
                  />
                </p>
              </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={4}>
              <div className="feature-card-common">
                <img src={Feature2} alt="img" />
                <h3>
                  <Translator
                    path={`${translatorId}.our-features.cards.invest-with-purpose.title`}
                  />
                </h3>
                <p>
                  <Translator
                    path={`${translatorId}.our-features.cards.invest-with-purpose.description`}
                  />
                </p>
              </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={4}>
              <div className="feature-card-common">
                <img src={Feature3} alt="img" />
                <h3>
                  <Translator
                    path={`${translatorId}.our-features.cards.thecnology-meets-nature.title`}
                  />
                </h3>
                <p>
                  <Translator
                    path={`${translatorId}.our-features.cards.thecnology-meets-nature.description`}
                  />
                </p>
              </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={4}>
              <div className="feature-card-common">
                <img src={Feature4} alt="img" />
                <h3>
                  <Translator
                    path={`${translatorId}.our-features.cards.gree-capital.title`}
                  />
                </h3>
                <p>
                  <Translator
                    path={`${translatorId}.our-features.cards.gree-capital.description`}
                  />
                </p>
              </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={4}>
              <div className="feature-card-common">
                <img src={Feature5} alt="img" />
                <h3>
                  <Translator
                    path={`${translatorId}.our-features.cards.palafita.title`}
                  />
                </h3>
                <p>
                  <Translator
                    path={`${translatorId}.our-features.cards.palafita.description`}
                  />
                </p>
              </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={4}>
              <div className="feature-card-common">
                <img src={Feature6} alt="img" />
                <h3>
                  <Translator
                    path={`${translatorId}.our-features.cards.cvm-approved.title`}
                  />
                </h3>
                <p>
                  <Translator
                    path={`${translatorId}.our-features.cards.cvm-approved.description`}
                  />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="feature-btns">
          <Button className="get-started-btn">
            <Translator
              path={`${translatorId}.our-features.button-getstarted`}
            />
          </Button>
          <Link to="/products">
            <Button className="browse-btn">
              <Translator
                path={`${translatorId}.our-features.button-browse-features`}
              />
            </Button>
          </Link>
        </div>
      </section>

      {/* product section start */}
      <section className="product-sec-wrap">
        <Container fluid>
          <Row>
            <Col sm={12} md={12} lg={6} xl={6}>
              <div className="product-left-image">
                <img src={productImg} alt="img" />
              </div>
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>
              <div className="product-right-details">
                <div className="product-title">
                  <p>
                    <Translator path={`${translatorId}.mid-banner.subtitle`} />
                  </p>
                  <h3>
                    <Translator path={`${translatorId}.mid-banner.title`} />
                  </h3>
                </div>
                <div className="common-product-listing">
                  <img src={Product1} alt="products" />
                  <div className="product-detail">
                    <h4>
                      <Translator
                        path={`${translatorId}.mid-banner.nft.title`}
                      />
                    </h4>
                    <p>
                      <Translator
                        path={`${translatorId}.mid-banner.nft.description`}
                      />
                    </p>
                  </div>
                </div>
                <div className="common-product-listing">
                  <img src={Product2} alt="products" />
                  <div className="product-detail">
                    <h4>
                      <Translator
                        path={`${translatorId}.mid-banner.esg.title`}
                      />
                    </h4>
                    <p>
                      <Translator
                        path={`${translatorId}.mid-banner.esg.description`}
                      />
                    </p>
                  </div>
                </div>
                <div className="common-product-listing">
                  <img src={Product3} alt="products" />
                  <div className="product-detail">
                    <h4>
                      <Translator
                        path={`${translatorId}.mid-banner.co2.title`}
                      />
                    </h4>
                    <p>
                      <Translator
                        path={`${translatorId}.mid-banner.co2.description`}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* getting started section starts */}
      <section className="get-started-section-wrap">
        <div className="common-title-wrap">
          <p>
            <Translator path={`${translatorId}.get-started.subtitle`} />
          </p>
          <h3>
            <Translator path={`${translatorId}.get-started.title`} />{" "}
            <b>
              <Translator path={`words.green`} />
            </b>
          </h3>
        </div>
        <Container className="custom-width">
          <Row className="g-2">
            <Col sm={6} md={4} lg={4} xl={4}>
              <div className="common-stared-cards">
                <div className="image-top-sec yellow">
                  <img src={getStarted1} alt="img" />
                </div>
                <div className="getting-started-details">
                  <img src={number1} alt="number" />
                  <h3>
                    <Translator
                      path={`${translatorId}.get-started.cards.first.title`}
                    />
                  </h3>
                  <p>
                    <Translator
                      path={`${translatorId}.get-started.cards.first.description`}
                    />
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={6} md={4} lg={4} xl={4}>
              <div className="common-stared-cards">
                <div className="image-top-sec sky-blue">
                  <img src={getStarted3} alt="img" />
                </div>
                <div className="getting-started-details">
                  <img src={number2} alt="number" />
                  <h3>
                    <Translator
                      path={`${translatorId}.get-started.cards.second.title`}
                    />
                  </h3>
                  <p>
                    <Translator
                      path={`${translatorId}.get-started.cards.second.description`}
                    />
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={6} md={4} lg={4} xl={4}>
              <div className="common-stared-cards">
                <div className="image-top-sec green">
                  <img src={getStarted2} alt="img" />
                </div>
                <div className="getting-started-details">
                  <img src={number3} alt="number" />
                  <h3>
                    <Translator
                      path={`${translatorId}.get-started.cards.third.title`}
                    />
                  </h3>
                  <p>
                    <Translator
                      path={`${translatorId}.get-started.cards.third.description`}
                    />
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="started-btns">
          <Button className="get-started-btn">
            <Translator path="words.register" />
          </Button>
        </div>
      </section>

      {/* footer banner section */}
      <FooterBanner withButton={false} />

      {/* footer section starts */}
      <Footer />
    </div>
  );
};

export default home;
