/* global dataLayer */
// Google Analytics

export const initGA = (trackingId) => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', trackingId);
};

