import React from "react";
import "./style.scss";
import Header from "../../components/Header";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../components/Footer";
import banner from "../../assets/images/banner.png";
import Story1 from "../../assets/images/about-us-story-1.png";
import Story2 from "../../assets/images/about-us-story-2.png";
import value1 from "../../assets/images/value1.png";
import value2 from "../../assets/images/value2.png";
import value3 from "../../assets/images/value3.png";
import value4 from "../../assets/images/value4.png";
import value5 from "../../assets/images/value5.png";
import value6 from "../../assets/images/value6.png";
import team from "../../assets/images/client.png";

import Translator from "../../components/Translator";
import OurOfficeBanner from "../../components/OurOfficeBanner";
import FooterBanner from "../../components/FooterBanner";

const pageTranslateId = "page-products";

const Products = () => {
  return (
    <div className="products-main">
      {/* //header section */}
      <Header />
      {/* about us banner starts */}
      <section className="products-banner">
        <div className="title-wrap">
          <h5>
            <Translator path={`${pageTranslateId}.main-banner.subtitle`} />
          </h5>
          <h3>
            <Translator path={`${pageTranslateId}.main-banner.title`} />
          </h3>
          <p>
            <Translator path={`${pageTranslateId}.main-banner.description`} />
          </p>
          <div className="button-group-wrap">
            <button className="join-team-btn">
              <Translator
                path={`${pageTranslateId}.main-banner.join-button-label`}
              />
            </button>
            <button className="company-btn">
              <Translator
                path={`${pageTranslateId}.main-banner.our-company-button-label`}
              />
            </button>
          </div>
        </div>
        <div className="banner-footer-img">
          <img src={banner} alt="banner" />
        </div>
      </section>

      {/* about us story section */}
      <section className="products-story">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="story-details-wrap">
                <h5>
                  <Translator path={`${pageTranslateId}.products.subtitle`} />
                </h5>
                <h3>
                  <Translator path={`${pageTranslateId}.products.title`} />
                </h3>
                <p>
                  <Translator
                    path={`${pageTranslateId}.products.description`}
                  />
                </p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="story-image">
                <img src={Story1} alt="story about us" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="story-image">
                <img src={Story2} alt="story about us" />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="story-details-wrap">
                <h5>
                  <Translator
                    path={`${pageTranslateId}.products.subtitle-section-two`}
                  />
                </h5>
                <h3>
                  <Translator
                    path={`${pageTranslateId}.products.title-section-two`}
                  />
                </h3>
                <p>
                  <Translator
                    path={`${pageTranslateId}.products.description-section-two`}
                  />
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="story-details-wrap">
                <h5>
                  <Translator path={`${pageTranslateId}.products.subtitle-section-three`} />
                </h5>
                <h3>
                  <Translator path={`${pageTranslateId}.products.title-section-three`} />
                </h3>
                <p>
                  <Translator
                    path={`${pageTranslateId}.products.description-section-three`}
                  />
                </p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="story-image">
                <img src={Story1} alt="story about us" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* our values */}
      <section className="our-value-wrap">
        <div className="title-wrap">
          <h5>
            <Translator path={`${pageTranslateId}.our-values.subtitle`} />
          </h5>
          <h3>
            <Translator path={`${pageTranslateId}.our-values.title`} />
          </h3>
        </div>
        <Row className="g-2">
          <Col sm={12} md={6} lg={4} xl={4}>
            <div className="value-card-wrap">
              <div className="card-image">
                <img src={value1} alt="card" />
              </div>
              <h3>
                <Translator
                  path={`${pageTranslateId}.our-values.inovation.title`}
                />
              </h3>
              <p>
                Vehicula ipsum a arcu cursus vitae rius morbi enim nunc faucibus
                integer mal esuada nunc vel risus.
              </p>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <div className="value-card-wrap">
              <div className="card-image">
                <img src={value2} alt="card" />
              </div>
              <h3>
                <Translator
                  path={`${pageTranslateId}.our-values.growth.title`}
                />
              </h3>
              <p>
                Vehicula ipsum a arcu cursus vitae rius morbi enim nunc faucibus
                integer mal esuada nunc vel risus.
              </p>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <div className="value-card-wrap">
              <div className="card-image">
                <img src={value6} alt="card" />
              </div>
              <h3>
                <Translator
                  path={`${pageTranslateId}.our-values.passion.title`}
                />
              </h3>
              <p>
                Vehicula ipsum a arcu cursus vitae rius morbi enim nunc faucibus
                integer mal esuada nunc vel risus.
              </p>
            </div>
          </Col>
        </Row>
      </section>

      {/* our office section */}
      <OurOfficeBanner />

      {/* footer banner section */}
      <FooterBanner isSimple />
      {/* //footer */}
      <Footer />
    </div>
  );
};

export default Products;
