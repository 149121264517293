import React, { useEffect } from "react";
import "./App.css";
import Routing from "./Routing";
import { initGA } from './analytics';

function App() {
  // Google Analytics
  useEffect(() => {
    initGA('G-4QFJ6GRZFY');
  }, []);

  return (
    <div className="App">
      <Routing />
    </div>
  );
}

export default App;
