function formatToBRL(text) {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return formatter.format(text);
}

export const Currency = {
  formatToBRL,
};
