import React from "react";

import { Container, Row, Col, NavLink } from "react-bootstrap";

import ComeAndViditleftSide from "../../assets/images/Come-and-vidit-left-side-img.png";
import PhoneIcons from "../../assets/images/phone-icon.svg";
import LocationIcons from "../../assets/images/location-incon.svg";

import Translator from "../Translator";

import "./style.scss";

const translateId = "our-office";

export default function OurOfficeBanner({ withButton = true }) {
  return (
    <section className="come-and-visit-on-wrapper custom-padding">
      <Container>
        <br />
        <br />
        <div className="common-title-wrap">
          <p>
            <Translator path={`${translateId}.subtitle`} />
          </p>
          <h3>
            <Translator path={`${translateId}.title`} />
          </h3>
        </div>
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="office-details">
              <h3>Manaus</h3>
              <p>
                <Translator path={`${translateId}.city-description`} />
              </p>
              <div className="phone-wrap">
                <span>
                  <img src={PhoneIcons} />
                </span>
                +55 (92) 9 8232 2910
              </div>

              <div className="location-wrap">
                <span>
                  <img src={LocationIcons} />
                </span>
                Rua Joaquim Sarmento, 377 - Centro, Manaus, AM, 69010-020
              </div>
            </div>
            {withButton && (
              <button className="btn btn-primary contact-us-btn">
                <NavLink href="/contact">Entre em contato</NavLink>
              </button>
            )}
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <div className="come-and-vidit-left-side-img">
              <img src={ComeAndViditleftSide} alt="Come-and-vidit-left-side" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
