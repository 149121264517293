import React, { useEffect, useState } from "react";
import "./style.scss";
import logo from "../../assets/images/Logo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Translator from "../Translator";
import { Currency } from "../../helper/Currency";

const Header = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUSDValue, setCurrentUSDValue] = useState(0);

  window.addEventListener("scroll", function () {
    let navbar = document.getElementById("menu-bar");
    if (window.pageYOffset >= 36) {
      navbar.classList.add("sticky");
    } else {
      navbar.classList.remove("sticky");
    }
  });

  useEffect(() => {
    setIsLoading(true);
    fetch("https://economia.awesomeapi.com.br/json/last/usd")
      .then(async (result) => {
        const responseBody = await result.json();
        setCurrentUSDValue(responseBody.USDBRL.bid);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Navbar className="navbar-wrap" id="menu-bar" collapseOnSelect expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link>
              <NavLink to="/">
                <Translator path="page-home.name" />
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink to="/about">
                <Translator path="page-about-us.name" />
              </NavLink>
            </Nav.Link>
            {/*<Nav.Link>
              <NavLink to="/products">
                <Translator path="page-products.name" />
              </NavLink>
            </Nav.Link>*/}
            <Nav.Link>
              <NavLink to="/contact">
                <Translator path="page-contact.name" />
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink to="/ecotrade">
                EcoTrade
                {/*<Translator path="page-ecotrade.name" />*/}
              </NavLink>
            </Nav.Link>
          </Nav>

          <Nav>
            <Nav.Link className="tag-line">
              Valor atual do dolar {Currency.formatToBRL(currentUSDValue)}
            </Nav.Link>
            <Button className="get-started-btn">
              <Translator path="header.button-label" />
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
