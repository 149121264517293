import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./view/Home";
import Contact from "./view/Contact";
import Personal from "./view/Personal";
import About from "./view/AboutUs";
import Products from "./view/Products";
import EcoTrade from "./view/EcoTrade";
const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/personal" element={<Personal />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/ecotrade" element={<EcoTrade />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
