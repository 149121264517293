import React from "react";
import { Container, Row, Col, NavLink } from "react-bootstrap";
import facebook from "../../assets/images/facebook.png";
import twitter from "../../assets/images/twitter.png";
import instagram from "../../assets/images/instagram.png";
import linkedIn from "../../assets/images/linked-in.png";
import youtube from "../../assets/images/youtube.png";
import emailUs from "../../assets/images/emailus.png";
import arrow from "../../assets/images/Arrow.svg";
import callUs from "../../assets/images/call-us.png";
import footerLogo from "../../assets/images/footer-logo.png";

import Translator from "../Translator";

import "./style.scss";
const footer = () => {
  return (
    <section className="footer-wrap">
      <Container>
        <Row>
          <Col sm={4} md={4} lg={2} xl={2}>
            <div className="footer-listing">
              <h3>
                <Translator path="footer.pages" />
              </h3>
              <ul>
                <li>
                  <NavLink href="/">
                    <Translator path="page-home.name" />
                  </NavLink>
                </li>
                <li>
                  <NavLink href="/about">
                    <Translator path="page-about-us.name" />
                  </NavLink>
                </li>
                <li>
                  <NavLink href="/products">
                    <Translator path="page-products.name" />
                  </NavLink>
                </li>
                <li>
                  <NavLink href="/contact">
                    <Translator path="page-contact.name" />
                  </NavLink>
                </li>
                <li>
                  <NavLink href="/ecotrade">
                    EcoTrade
                    {/*<Translator path="page-ecotrade.name" />*/}
                  </NavLink>
                </li>
                <li>
                  <NavLink href="/">Junte-se ao nosso time</NavLink>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={4} md={4} lg={2} xl={2}>
            <div className="footer-listing">
              <h3>
                <Translator path="footer.pages" />
              </h3>
              <ul>
                <li>Blog</li>
                <li>Blog post</li>
                <li>Team</li>
                <li>Coming soon</li>
              </ul>
            </div>
          </Col>
          <Col sm={4} md={4} lg={2} xl={2}>
            <div className="footer-listing">
              <h3>
                <Translator path="footer.utility-pages.label" />
              </h3>
              <ul>
                <li>
                  <Translator path="footer.utility-pages.start-here" />
                </li>
                <li>
                  <Translator path="footer.utility-pages.style-guide" />
                </li>
                <li>
                  <Translator path="footer.utility-pages.licenses" />
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={4} md={4} lg={2} xl={2}>
            <div className="footer-listing social-icon">
              <h3>
                <Translator path="footer.follow-us" />
              </h3>
              <ul>
                <li>
                  <span>
                    <img src={facebook} alt="facebook" />
                  </span>
                  Facebook
                </li>
                <li>
                  <span>
                    <img src={twitter} alt="Twitter" />
                  </span>
                  Twitter
                </li>
                <li>
                  <span>
                    <img src={instagram} alt="Instagram" />
                  </span>
                  Instagram
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/bvm12/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <img src={linkedIn} alt="LinkedIn" />
                    </span>
                    LinkedIn
                  </a>
                </li>
                <li>
                  <span>
                    <img src={youtube} alt="Youtube" />
                  </span>
                  Youtube
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={8} md={8} lg={4} xl={4}>
            <div className="footer-listing">
              <h3>
                <Translator path="footer.contacts.label" />
              </h3>
              <div className="email-us">
                <img src={emailUs} alt="email" />
                <div className="email-detail">
                  <p>
                    <Translator path="footer.contacts.email" />
                  </p>
                  <h3>
                    <NavLink href="/contact">
                      contato@bvm12.com.br
                      <span>
                        <img src={arrow} alt="arrow" />
                      </span>
                    </NavLink>
                  </h3>
                </div>
              </div>
              <div className="call-us">
                <img src={callUs} alt="email" />
                <div className="email-detail">
                  <p>
                    <Translator path="footer.contacts.call" />
                  </p>
                  <h3>
                    <a
                      href="https://wa.me/+5592982322910?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20BVM12"
                      target="_blank"
                      rel="noreferrer"
                    >
                      + 55 92 3199-6289
                      <span>
                        <img src={arrow} alt="arrow" />
                      </span>
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="copy-right-wrapper">
          <img src={footerLogo} alt="logo" />
          <p>Copyright © BVM12 - Plataforma de Captação para Startups Ltda. CNPJ 38.730.632/0001-83 | Designed by Flying Kite</p>
        </div>
      </Container>
    </section>
  );
};

export default footer;
